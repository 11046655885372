<template>
  <div class="col-12 px-0">
    <div class="box-wrapper-gray py-4">
      <div class="row">
        <div class="col-6"><h5>{{area.name}}</h5></div>
        <div class="col-6 text-primary text-right"><span class="h5" @click="showTransactions = true" v-b-tooltip.hover title="Click for details">{{ creditBalance }}</span></div>
      </div>
      <spinner :show="loading" :use-modal="false" :message="progressMessage" size="30px"></spinner>
      <template v-if="!loading">
        <saved-card
          v-for="(card, idx) in cards"
          :card="card"
          :key="area.service_area_id + '_' + idx"
          :ui-disabled="UiDisabled"
          :num-cards="cards.length"
          @retest="testChargeCard(card.id)"
          @delete="deleteCard(card.id)"
          @move-up="moveCard(card.id, 'up')"
          @move-down="moveCard(card.id, 'down')"
        />
        <div v-if="!UiDisabled" class="info text-center">
          <a @click.prevent="$emit('set:current-area', area)" href="#" class="font-weight-normal"><i class="fa fa-plus"></i> Add a new Card</a>
        </div>
      </template>
      <transactions-modal :area="area" :has-credits="hasCredits" :balance="creditBalance" :client-id="clientId" :show="showTransactions" @close="onTransactionsClosed" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import api from '@/clients/api'
import SavedCard from '@/shared/card.vue'
import TransactionsModal from '@/modals/credit_transactions_modal.vue'
import Spinner from '@/shared/spinner.vue'
import { formatCurrency } from '@/utils'
import pubsub_mixin from '@/shared/pubsub_mixin'
export default {
  props: {
    area: {
      type: Object,
      required: true
    },
    clientId: {
      type: Number,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    waitForUpdate: {
      type: Boolean,
      default: false
    }
  },
  mixins: [pubsub_mixin],
  components: {
    TransactionsModal,
    SavedCard,
    Spinner
  },
  data() {
    return {
      cards: {},
      UiDisabled: false,
      rawLoading: false,
      showTransactions: false
    }
  },
  computed: {
    hasCredits() {
      return this.creditBalanceCents < 0
    },
    creditBalanceCents() {
      return this.area.creditBalance * 1
    },
    creditBalance() {
      let balancePrefix = 'Debit'
      if (this.creditBalanceCents < 0) {
        balancePrefix = 'Credit'
      }
      return balancePrefix + ": " + formatCurrency(Math.abs(this.creditBalanceCents) / 100)
    },
    loading() {
      return this.rawLoading || this.waitForUpdate
    },
    progressMessage() {
      if (this.waitForUpdate) {
        return 'Updating...'
      }
      return 'Loading...'
    }
  },
  mounted() {
    this.rawLoading = true
    this.getCardsForArea()
  },
  methods: {
    deleteCard(cardId) {
      this.$emit('set:updating-area', this.area)
      this.rawLoading = true
      api.deleteCard(cardId).then((response) => {
      }, (errors) => {
        this.rawLoading = false
        this.$flasher.errors(errors.response.data.errors)
      })
    },
    getCardsForArea() {
      api.getCardsForArea(this.clientId, this.area.key).then(response => {
        this.cards = response.data
        this.rawLoading = false
        this.UiDisabled = false
        this.subscribe()
      })
    },
    moveCard(cardId, dir) {
      this.UiDisabled = true
      api.moveCard(cardId, dir)
    },
    onTransactionsClosed() {
      this.showTransactions = false
      this.$emit('updated')
    },
    testChargeCard(cardId) {
      this.$emit('set:updating-area', this.area)
      this.rawLoading = true
      api.testChargeCard(cardId).then((response) => {
        this.rawLoading = false
      }, (errors) => {
        this.rawLoading = false
        this.$flasher.errors(errors.response.data.errors)
      })
    },
    subscribe() {
      if (!this.subscribed) {
        this.fayeSubscribe('/clients/' + this.clientId + '/cards/**', 'cards updated', (channel, card) => {
          console.log('faye msg', channel, card)
          if (card && (card.area_key != this.area.key)) {
            console.log('faye subscribe return')
            return
          }
          const parts = channel.split('/')
          const clientId = parts[2]
          const action = parts[4]
          const existingCard = this.cards.find(c => c.id == card.id)
          console.log(channel, 'clientId', clientId, 'action', action, 'card', card)
          var idx = -1
          if (existingCard) {
            idx = this.cards.indexOf(existingCard)
          }
          if (action == 'added') {
            if (idx == -1) {
              this.cards.push(card)
            }
          }
          if (action == 'deleted') {
            if (idx > -1) {
              Vue.delete(this.cards, idx)
            }
          }
          if (action == 'updated') {
            this.getCardsForArea()
          }
          this.$emit('updated')
        })
        this.subscribed = true
      }
    }
  }
}
</script>
