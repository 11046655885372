<template>
  <div class="row px-0 mx-0">
    <div v-if="!loading" class="col-12">
      <div class="mt-5 mb-4">
        <h1 class="main-head text-center">Payment Settings</h1>
      </div>
    </div>
    <h6 v-if="inDevMode" class="alert bg-warning">Use 4000000000000077 to be able to transfer payments to cleaners right away</h6>
    <div id="card-element"></div>
    <template v-show="!loading">
      <div v-if="isGlobalAdmin" class="row">
        <b-form-checkbox :disabled="updatingClient" v-model="markInvoicesPaid">
          Automatically mark new invoices as paid without billing the client
        </b-form-checkbox>
        <spinner v-if="updatingClient" message="" size="14px" />
      </div>
      <cards-for-area v-for="area in areas" :area="area" :client-id="clientId" :customer="customer" :wait-for-update="updatingArea == area" @set:current-area="currentArea = area" @updated="onCardsUpdated" @set:updating-area="updatingArea = $event" :key="area.id" />
    </template>
    <card-modal
      :client-id="clientId"
      :customer="customer"
      :show="currentArea != null"
      :stripe-info="currentArea"
      @close="currentArea = null"
      @set:updating-area="updatingArea = $event" />
  </div>
</template>
<script>
import Vue from 'vue'
import api from '@/clients/api'
import Spinner from '@/shared/inline_spinner.vue'
import CardModal from '@/modals/card_modal.vue'
import CardsForArea from '@/shared/cards_for_area.vue'

export default {
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  components: {
    CardModal,
    CardsForArea,
    Spinner
  },
  data() {
    return {
      areas: [],
      currentArea: null,
      customer: null,
      rawMarkInvoicesPaid: null,
      loading: false,
      updatingClient: false,
      updatingArea: null
    }
  },
  computed: {
    inDevMode() {
      return Window.devMode === true
    },
    markInvoicesPaid: {
      get() {
        return this.rawMarkInvoicesPaid
      },
      set(v) {
        this.updateMarkInvoicesPaid(v)
      }
    }
  },
  mounted() {
    this.getClientPaymentInfo()
  },
  methods: {
    getCardsForArea(area) {
      this.loading = true
      api.getCardsForArea(this.clientId, this.currentArea.key).then(response => {
        this.loading = false
        Vue.set(this.cards[this.currentArea.key], response.data)
      })
    },
    getClientPaymentInfo() {
      this.loading = true
      api.getClientPaymentInfo(this.clientId).then(response => {
        this.loading = false
        this.areas = response.data.areas
        this.customer = response.data.customer
        this.rawMarkInvoicesPaid = response.data.mark_invoices_paid
      })
    },
    onCardsUpdated() {
      this.updatingArea = null
      this.getClientPaymentInfo()
    },
    updateMarkInvoicesPaid(newValue) {
      this.updatingClient = true
      let clientParams = { mark_invoices_paid: newValue }
      api.updateClient(this.clientId, clientParams).then(response => {
        this.updatingClient = false
      })
    }
  }
}
</script>
