<template>
  <div class="col-12">
    <phone-modal :id="id" :label="label" :number="number" :primary="primary" @save="save" @hide="closeModal" :show="showModal"></phone-modal>
    <p v-if="canAddPhone" class="text-center blue-text">
      <a @click="addPhone" class="cursor-pointer"><i class="fal fa-plus-circle mr-2" aria-hidden="true"></i> Add phone number</a>
    </p>
    <div v-for="(phone, idx) in phones" class="phone-row">
      <div class="mt-1 mb-1 d-flex justify-content-between">
        <div class="col-auto">
          <p class="my-0">
            <template v-if="readonly">
              <i v-if="phone.primary" class="fal fa-check-circle green-text mr-2" v-b-tooltip.hover title="Primary phone"></i>
              <i v-else class="fal mr-2 pr-2"></i>
            </template>
            <template v-else>
              <i v-if="phone.primary" class="fal fa-check-circle green-text mr-2"></i>
              <i v-else class="fal fa-circle mr-2" @click="onMakePrimary(idx)" v-b-tooltip.hover title="Select as primary phone"></i>
            </template>
            {{phone.label}}
          </p>
        </div>
        <div class="col-auto"><p class="my-0">{{phone.number}}</p></div>
        <div v-if="readonly" class="col-auto d-flex">
          <span :title="phone.confirmation_sent_ago">{{ phone.confirmation_status }}</span>
          <a v-if="canRequireConfirm(idx)" @click="confirm(idx)" class="btn btn-link text-danger py-0">{{ confirmLabel }}<i class="fas fal-solid fa-check-double"></i></a>
        </div>
        <div v-if="!readonly" class="d-flex">
          <a @click="edit(idx)" class="blue-text cursor-pointer"><i class="fal fa-edit"></i></a>
          <template v-if="!phone.primary">
            <span class="mr-2 ml-2">/</span>
            <a @click="deletePhone(idx)" class="red-text cursor-pointer"><i class="fal fa-times-circle"></i></a>
          </template>
        </div>
      </div>
      <hr class="mt-0">
    </div>
  </div>
</template>
<script>
import PhoneModal from './phone_modal.vue'
import api from '@/admins/api'

export default {
  components: {
    PhoneModal
  },
  props: {
    readonly: {
      type: Boolean,
      default: true
    },
    phones: {
      type: Array
    }
  },
  data() {
    return {
      editedIdx: null,
      newPhone: false,
      id: null,
      label: '',
      number: '',
      primary: false
    }
  },
  computed: {
    canAddPhone() {
      return (this.phones.length < 4) && !this.readonly
    },
    confirmLabel() {
      if (this.isStaff) {
        return 'Require confirmation'
      }
      return 'Confirm'
    },
    showModal() {
      return (this.editedIdx != null) || (this.newPhone)
    }
  },
  methods: {
    addPhone() {
      this.newPhone = true
    },
    canRequireConfirm(idx) {
      const phone = this.phones[idx]
      if (this.isStaff) {
        return true
      }

      return phone.confirmation_status == 'Unconfirmed'
    },
    edit(idx) {
      this.id = this.phones[idx].id
      this.label = this.phones[idx].label
      this.number = this.phones[idx].number
      this.primary = this.phones[idx].primary
      this.editedIdx = idx
    },
    confirm(idx) {
      const phoneId = this.phones[idx].id
      api.requirePhoneConfirmation(phoneId).then(response => {
        this.closeModal()
        this.$emit('updated')
        this.$flasher.success('Message sent')
      }, error => {
        this.$flasher.error(error.response.data.errors)
      })
    },
    closeModal() {
      this.id = null
      this.label = null
      this.number = null
      this.primary = null
      this.editedIdx = null
      this.newPhone = false
    },
    onMakePrimary(idx) {
      this.makePrimary(idx)
      this.$emit('updated')
    },
    makePrimary(idx) {
      for (var i = 0; i < this.phones.length; i++) {
        this.$emit('update:phone-attr', { idx: i, attr: 'primary', value: (idx == i) } )
      }
    },
    save(phone) {
      for(var attr in phone) {
        if ( (attr == 'primary') && (phone[attr]) ) {
          this.makePrimary(this.editedIdx)
        }
        if ( (attr != 'confirmation_status') && (attr != 'confirmation_setn_ago') ) {
          this.$emit('update:phone-attr', { idx: this.editedIdx, attr: attr, value: phone[attr] })
        }
      }
      this.closeModal()
      this.$emit('updated')
    },
    deletePhone(idx) {
      this.$dialog.confirm('Are you sure you want to delete this phone number?', {
        okText: 'Yes',
        cancelText: 'No'
      })
      .then((dialog) => {
        this.$emit('update:phone-attr', { idx: idx, attr: '_destroy', value: 1 })
        this.$emit('updated')
      })
    }
  }
}
</script>
