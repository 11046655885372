<template>
  <div>
    <base-modal dom-id="transactionsModal" :show="show" modal-size="modal-xl" :title="title" @close="$emit('close')" body-class="modal-body text-center pt-0">
      <div slot="content">
        <spinner :show="loading" message="" />
        <template v-if="!loading">
          <div class="row mb-2">
            <div class="col">
              Balance: {{ balance }}
              <template v-if="canRefund">
                <div v-if="askRefundConfirmation" class="row my-2 text-danger font-weight-bolder">
                  <div class="col">
                    <label class="checkbox-set">
                      Confirm refunding the same amount
                      <input type="checkbox" v-model="confirmRefund" />
                      <span class="checkmark checkmark-blue"></span>
                    </label>
                  </div>
                </div>
                <button @click="refund" class="btn btn-sm btn-danger">Refund as money</button>
              </template>
            </div>
          </div>
          <b-table-lite striped hover :items="transactions">
            <template #cell(invoice)="data">
              <span v-html="data.value"></span>
            </template>
          </b-table-lite>
        </template>
      </div>
    </base-modal>
  </div>
</template>
<script>
import BaseModal from '@/modals/base_modal.vue'
import Spinner from '@/shared/inline_spinner.vue'
import api from '@/clients/api'
export default {
  props: {
    area: {
      type: Object,
      required: true
    },
    clientId: {
      type: Number,
      required: true
    },
    balance: {
      type: String,
      required: true
    },
    hasCredits: {
      type: Boolean,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  components: { BaseModal, Spinner },
  computed: {
    areaKey() {
      return this.area.key
    },
    canRefund() {
      return this.isStaff && this.hasCredits
    },
    customerId() {
      return this.area.customerId
    },
    title() {
      return 'Credit balance adjustments'
    },
  },
  data() {
    return {
      loading: true,
      askRefundConfirmation: false,
      confirmRefund: false,
      transactions: null
    }
  },
  methods: {
    getTransactions() {
      this.loading = true
      api.getCreditTransactionsFromStripe(this.areaKey, this.customerId).then((response) => {
        this.loading = false
        this.transactions = response.data.transactions
      })
    },
    refund() {
      this.askRefundConfirmation = false
      this.loading = true
      api.refundCredits(this.areaKey, this.clientId, this.confirmRefund).then((response) => {
        this.confirmRefund = false
        this.loading = false
        this.$emit('close')
      }, (errors) => {
        this.loading = false
        if (errors.response.data.errors[0] == 'Confirmation required') {
          this.askRefundConfirmation = true
          this.confirmRefund = false
          this.$flasher.error('You need to confirm the refund')
        } else {
          this.$flasher.errors(errors.response.data.errors)
        }
      })
    }
  },
  watch: {
    show() {
      if (this.show === true) {
        if (this.transactions == null) {
          this.getTransactions()
        }
      }
    }
  }
}
</script>
