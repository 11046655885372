<template>
  <div class="box-wrapper pos-relative manage-prop-cont" :class="propertyClass">
    <div v-if="!deleted" class="edit-close-sec">
      <div>
        <button @click="$emit('go:property', property.id)" class="btn btn-link edit-pos manage-property"><img src="@images/edit-icon02.png" width="40px"></button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <img class="img-fluid d-block mb-3 mx-auto rounded" :src="mapUrl">
      </div>
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
        <div class="prop-right-in">
          <div class="">
            <h3 class="sub-head mb-2">{{address}}</h3>
            <p class="gray-text">{{bedrooms}}
              <br>{{bathrooms}}
              <br>{{area}}
              <br><span v-if="active" class="badge badge-pill badge-danger">Active Schedule</span>
              <span v-if="hasIcal" class="badge badge-pill badge-primary">iCal</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xs-12 col-md-6">
        <button v-if="canBook" @click="$emit('book', property.id)" class="btn btn-default btn-block active my-2">Make New Booking</button>
      </div>
      <div v-if="hasVisits" class="col-xs-12 col-md-6">
        <button @click="$emit('go:visits', property.id)" class="btn btn-default btn-block active my-2">Visits</button>
      </div>
      <div class="col-6 mx-auto">
        <a v-if="canRestoreProperty" :href="restoreUrl" class="btn btn-warning btn-block active">Restore Property</a>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <a v-if="canRecordHWE" :href="recordHWEUrl" class="btn btn-link">Record WHE</a>
        <a v-if="canRecordCleaning" :href="recordCleaningUrl" class="btn btn-link">Record Cleaning</a>
        <a v-if="canImportIcal" href="#" @click.prevent="$emit('ask-ical-url', property.id)" class="btn btn-link">Book from ICAL</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  computed: {
    canBook() {
      return !this.deleted && (this.isStaff || this.isClient)
    },
    canImportIcal() {
      return this.property.can_import_ical
    },
    canRecordCleaning() {
      return !this.deleted && this.isStaff
    },
    canRecordHWE() {
      return !this.deleted && this.isStaff
    },
    canRestoreProperty() {
      return this.deleted && (this.isStaff || this.isClient)
    },
    hasIcal() {
      if (this.property) {
        return this.property.ical_url != null
      }
    },
    hasVisits() {
      if (this.property) {
        return !this.property.isNew
      }
    },
    active() {
      if (this.property) {
        return this.property.active
      }
    },
    address() {
      if (this.property) {
        return this.property.address
      }
    },
    bathrooms() {
      if (this.property.details) {
        return this.property.details.bathrooms
      }
    },
    bedrooms() {
      if (this.property.details) {
        return this.property.details.bedrooms
      }
    },
    area() {
      if (this.property.details) {
        return this.property.details.area
      }
    },
    mapUrl() {
      return this.property.map_url
    },
    restoreUrl() {
      return this.property.restore_url
    },
    deleted() {
      return this.property.deleted
    },
    recordCleaningUrl() {
      return '/properties/' + this.property.id + '/record_cleaning'
    },
    recordHWEUrl() {
      return '/properties/' + this.property.id + '/record_hwe'
    },
    propertyClass() {
      if (this.deleted) {
        return 'bg-danger text-white'
      }
    }
  }
}
</script>
