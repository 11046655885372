<template>
  <b-modal :visible="show" ref="modal" title-html="<h5>Add phone number</h5>" title-class="sub-head mx-auto" @hidden="$emit('hide')">
    <form>
      <div class="row">
        <div class="col-md">
          <my-text-input v-model="ourLabel" label="Label (eg.office,home,personal)" field="label" placeholder="Eg. Main phone"></my-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <phone-field v-model="ourNumber" />
        </div>
        <div class="col-md-4 mt-3">
          <b-form-checkbox id="primary-cb" v-model="ourPrimary" name="primary">Is Primary?</b-form-checkbox>
        </div>
        <div class="col-md-12">
          <ul class="list-unstyled red-text">
            <li class="text-center" v-for="error in errors">
              <p class="my-0 text-center">
                <span><i class="fal fa-exclamation-circle mr-2"></i>{{error}}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </form>
    <div slot="modal-footer">
      <div class="text-center mx-auto">
        <button @click="save" type="button" aria-label="Close" class="btn btn-mini btn-primary">Save Phone</button>
        <button @click="$emit('hide')" type="button" aria-label="Close" class="btn btn-mini btn-gray">Cancel</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Vue from 'vue'
import api from '@/clients/api'
import MyTextInput from './inputs/base.vue'
import PhoneField from './fields/phone.vue'

export default {
  props: ['id', 'label', 'number', 'primary', 'show'],
  data() {
    return {
      errors: null,
      ourLabel: '',
      ourNumber: '',
      ourPrimary: false
    }
  },
  components: { MyTextInput, PhoneField },
  methods: {
    save() {
      var attrs = {
        id: null,
        label: this.ourLabel,
        number: this.ourNumber,
        primary: this.ourPrimary
      }
      if (this.id) {
        attrs.id = this.id
      }
      this.errors = null
      api.validatePhone(attrs).then((response) => {
        this.$emit('save', attrs)
      }, (error) => {
        this.errors = error.response.data.errors
      })
    }
  },
  watch: {
    label(newValue) {
      this.ourLabel = newValue
    },
    number(newValue) {
      this.ourNumber = newValue
    },
    primary(newValue) {
      this.ourPrimary = newValue
    }
  }
}
</script>
