<template>
  <div class="card my-2 p-2" :class="cardColor">
    <div class="info text-center">
      <div v-if="!UiDisabled" class="card-actions d-inline-block">
        <template v-if="numCards > 1">
          <a v-if="upEnabled" href="#" @click.prevent="$emit('move-up')" title="Move up"><i class="fa fa-arrow-up m-1"></i></a>
          <a v-if="downEnabled" href="#" @click.prevent="$emit('move-down')" title="Move down"><i class="fa fa-arrow-down m-1"></i></a>
        </template>
      </div>
      <div class="cards-info d-inline-block">
        <img class="card-icon p-1" :src="icon" />
        <span class="font-weight-normal">{{card.date}}</span>
        <span class="font-weight-normal">XXXX-XXXX-XXXX-{{card.last4}}</span>
        <clip-loader v-if="UiDisabled" :loading="true" class="d-inline-block" size="15px" />
      </div>
      <div v-if="!UiDisabled" class="card-actions d-inline-block">
        <a v-if="numCards > 1" href="#" @click.prevent="$emit('delete')"><i class="fa fa-times red-text m-1"></i></a>
      </div>
      <span v-if="lastError && !cardDisabled" class="badge badge-pill badge-warning">{{ lastError }}</span>
      <template v-if="cardDisabled">
        <span class="badge badge-pill badge-danger">{{ lastError }}</span>
        <button class="btn btn-link" @click="$emit('retest')">Retry</button>
      </template>
    </div>
    <div class="text-center">{{ info }}</div>
  </div>
</template>
<script>
import MastercardIcon from '@images/mastercard-icon.png'
import VisaIcon from '@images/visa-icon.png'
import OtherCardIcon from '@images/card-icon.png'
import { ClipLoader } from 'vue-spinner/dist/vue-spinner.min.js'
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    UiDisabled: {
      type: Boolean,
      required: true
    },
    numCards: {
      type: Number
    }
  },
  computed: {
    cardColor() {
      if (this.cardDisabled) {
        return 'border-danger'
      }
      return 'border-success'
    },
    cardDisabled() {
      return this.card.enabled != true
    },
    icon() {
      if (this.card.brand == 'mastercard') {
        return MastercardIcon
      }
      if (this.card.brand == 'visa') {
        return VisaIcon
      }
      return OtherCardIcon
    },
    info() {
      return this.card.info
    },
    lastError() {
      return this.card.error
    },
    upEnabled() {
      return this.pos > 0
    },
    downEnabled() {
      return (this.pos + 1) < this.numCards
    },
    pos() {
      return this.card.order
    }
  }
}
</script>
