<template>
  <base-modal
    bodyClass="modal-body"
    classes="modal-content-quantity"
    dom-id="cardModal"
    :show="show"
    :title="title"
    @close="close">
    <div slot="content">
      <h6>Details of the card</h6>
      <div class="row my-5">
        <div class="col-sm-12">
          <card v-if="show" 
            class='stripe-card' 
            ref="stripeCard" 
            :class='{ complete }' 
            :stripe="stripe" 
            :options='stripeOptions' 
            @change='complete = $event.complete' />
        </div>
      </div>
      <h6>Details of the card owner's</h6>
      <div class="row">
        <div class="col-sm-6"><name-field v-model="name" rules="required|min:5" label="Name" /></div>
        <div class="col-sm-6"><phone-field v-model="phone" /></div>
      </div>
      <div class="row">
        <div class="col-sm-6"><address-field v-model="address" /></div>
        <div class="col-sm-6"><city-field v-model="city" /></div>
      </div>
      <div class="row">
        <div class="col-sm"><zip-field v-model="zipCode" /></div>
        <div class="col-sm"><state-field v-model="state" /></div>
        <div class="col-sm"><country-field v-model="countryCode" /></div>
      </div>
    </div>

    <div slot="footer">
      <spinner :show="loading || noCustomer" :use-modal="false" :message="message" size="32px"></spinner>
      <button v-if="!noCustomer && !loading" @click='submit' :disabled='!complete'  class="btn btn-green">{{submitTitle}}</button>
    </div>
  </base-modal>
</template>
<script>
import { Card } from 'vue-stripe-elements-plus'

import Spinner from '@/shared/spinner.vue'
import BaseModal from './base_modal.vue'
import AddressField from '@/shared/fields/address.vue'
import CityField from '@/shared/fields/city.vue'
import StateField from '@/shared/fields/state.vue'
import ZipField from '@/shared/fields/zip.vue'
import CountryField from '@/shared/fields/country.vue'
import PhoneField from '@/shared/fields/phone.vue'
import NameField from '@/shared/inputs/text.vue'

import api from '@/clients/api'
export default {
  props: {
    stripeInfo: {
      type: Object
    },
    clientId: {
      type: Number,
      required: true
    },
    customer: {
      type: Object
    },
    title: {
      type: String,
      default: 'Add New Card'
    },
    show: {
      type: Boolean,
      required: true
    },
    token: {
      type: String
    }
  },
  components: { BaseModal, Card, Spinner, AddressField, CityField, StateField, ZipField, PhoneField, NameField, CountryField },
  data() {
    return {
      complete: false,
      loading: false,
      message: 'This can take a minute please wait',
      stripeCheck: false,
      stripeOptions: {
        hidePostalCode: true
      },
      ourName: null,
      ourPhone: null,
      ourAddress: null,
      ourCity: null,
      ourZipCode: null,
      ourState: null,
      ourCountryCode: null,
      faye: null
    }
  },
  created() {
    this.faye = new Faye.Client(Window.faye_url, {
      retry: 5
    })
    this.faye.subscribe('/clients/' + this.clientId + '/stripe/**').withChannel((channel, customer) => {
      const parts = channel.split('/')
      const clientId = parts[2]
      const object = parts[4]
      const action = parts[5]
      if ( (object == 'customers') & (this.stripeInfo.key == customer.area_key) ) {
        if ( (action == 'added') || (action == 'exists') ) {
          this.addCard()
        }
      }
    })
  },
  computed: {
    newCard() {
      return true
    },
    noCustomer() {
      return this.customer == null
    },
    stripe() {
      return Stripe(this.stripeKey)
    },
    stripeKey() {
      if (this.stripeInfo) {
        return this.stripeInfo.public_key
      }
    },
    submitTitle() {
      if (this.newCard) {
        return 'Add credit card'
      } else {
        return 'Update credit card'
      }
    },
    customerName() {
      if (this.customer) {
        return this.customer.name
      }
    },
    customerPhone() {
      if (this.customer) {
        return this.customer.phone
      }
    },
    customerAddressLine1() {
      if (this.customer) {
        return this.customer.address.line1
      }
    },
    customerAddressState() {
      if (this.customer) {
        return this.customer.address.state
      }
    },
    customerAddressCity() {
      if (this.customer) {
        return this.customer.address.city
      }
    },
    customerAddressCountry() {
      if (this.customer) {
        return this.customer.address.country
      }
    },
    customerAddressZipCode() {
      if (this.customer) {
        return this.customer.address.postal_code
      }
    },
    name: {
      get() {
        if (this.ourName) {
          return this.ourName
        }
        return this.customerName
      },
      set(v) {
        this.ourName = v
      }
    },
    phone: {
      get() {
        if (this.ourPhone) {
          return this.ourPhone
        }
        return this.customerPhone
      },
      set(v) {
        this.ourPhone = v
      }
    },
    address: {
      get() {
        if (this.ourAddress) {
          return this.ourAddress
        }
        return this.customerAddressLine1
      },
      set(v) {
        this.ourAddress = v
      }
    },
    zipCode: {
      get() {
        if (this.ourZipCode) {
          return this.ourZipCode
        }
        return this.customerAddressZipCode
      },
      set(v) {
        this.ourZipCode = v
      }
    },
    state: {
      get() {
        if (this.ourState) {
          return this.ourState
        }
        return this.customerAddressState
      },
      set(v) {
        this.ourState = v
      }
    },
    city: {
      get() {
        if (this.ourCity) {
          return this.ourCity
        }
        return this.customerAddressCity
      },
      set(v) {
        this.ourCity = v
      }
    },
    countryCode: {
      get() {
        if (this.ourCountryCode) {
          return this.ourCountryCode
        }
      },
      set(v) {
        this.ourCountryCode = v
      }
    }
  },
  methods: {
    checkCustomer() {
      api.ensureStripeCustomer(this.clientId, this.stripeInfo.key)
    },
    addCard() {
      this.$emit('set:updating-area', this.stripeInfo)

      api.createSetupIntent(this.clientId, this.stripeInfo.key).then(response => {
        const clientSecret = response.data.clientSecret
        this.stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: this.$refs.stripeCard.$refs.element._element,
            billing_details: {
              name: this.name,
              email: this.customer.email,
              phone: this.phone,
              address: {
                city: this.city,
                line1: this.address,
                postal_code: this.zipCode,
                country: this.countryCode,
                state: this.state
              }
            },
            metadata: {
              client_id: this.clientId,
              area_key: this.stripeInfo.key
            }
          }
        }).then((result) => {
          this.loading = false
          if (result.error) {
            this.$flasher.error(result.error.message)
          } else {
            this.$emit('updated')
            this.$emit('close')
            this.$flasher.success('Card successfully added')
          }
        })
      })
    },
    close() {
      this.$emit('close')
    },
    submit() {
      this.loading = true
      if (this.newCard) {
        this.checkCustomer()
        if (process.env.NODE_ENV == 'production') {
          this.$gtm.trackEvent({
              event: 'added-payment-method',
              client: {
                id: this.clientId,
              }
          });
        }
      } else {
        console.log("updatind existing card")
      }
    }
  }
}
</script>

